import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import RttIcon from '@mui/icons-material/Rtt';
import RuleFolderIcon from '@mui/icons-material/RuleFolder';
import BugReportIcon from '@mui/icons-material/BugReport';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import DnsIcon from '@mui/icons-material/Dns';
import AppsIcon from '@mui/icons-material/Apps';
import PolicyIcon from '@mui/icons-material/Policy';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import TuneIcon from '@mui/icons-material/Tune';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DomainIcon from '@mui/icons-material/Domain';
import PeopleIcon from '@mui/icons-material/People';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import CableIcon from '@mui/icons-material/Cable';
import HubIcon from '@mui/icons-material/Hub';



const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  SSC SIEM
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="50px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`../../assets/user.png`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                
                </Typography>
                
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/dashboard"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Real Time"
              to="/dashboard"
              icon={<RttIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Security Events
            </Typography>
            <Item
              title="Security Events"
              to="/securityEvents"
              icon={<SafetyCheckIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="FIM"
              to="/fim"
              icon={<RuleFolderIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Vulnerabilities"
              to="/Vulnerabilities"
              icon={<BugReportIcon />}
              selected={selected}
              setSelected={setSelected}
            />


            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >


            Security Analysis
            </Typography>
            <Item
              title="Malware Detection"
              to="/VirusTotal"
              icon={<BugReportIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Threat Intelligence"
              to="/ThreatIntelligenceView"
              icon={<BubbleChartIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Facts Collection"
              to="/FactsCollectionView"
               
              icon={<PolicyIcon />}
              selected={selected}
              setSelected={setSelected}
            />


            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
            Correlation Streams
            </Typography>


            <Item
              title="Alerts"
              to="/CorrelationAlertsView"
               
              icon={<PolicyIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Events"
              to="/CorrelationEventsView"
               
              icon={<PolicyIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Streams"
              to="/CorrelationStreamssView"
               
              icon={<PolicyIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Rules"
              to="/CorrelationRulessView"
               
              icon={<PolicyIcon />}
              selected={selected}
              setSelected={setSelected}
            />



          <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
            System Status
            </Typography>
            <Item
              title="Agents"
              to="/agents"
              icon={<DnsIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Services"
              to="/services"
              icon={<AppsIcon />}
              selected={selected}
              setSelected={setSelected}
            />


            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              SOC
            </Typography>

            <Item
              title="Alerts"
              to="/socAlerts"
              icon={<NotificationImportantIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Cases"
              to="/socCases"
              icon={<AssignmentIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Integrations
            </Typography>
            <Item
              title="Connectors"
              to="/integrations"
              icon={<CableIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="External Integrations"
              to="/pie"
              icon={<HubIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Compliance
            </Typography>
            <Item
              title="GDPR"
              to="/gdpr"
              icon={<FactCheckIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="NIST 800-53"
              to="/nist"
              icon={<AssuredWorkloadIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="PCI DSS"
              to="/pciDss"
              icon={<CheckBoxIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Policy Monitoring"
              to="/policyMonitoring"
              icon={<TuneIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Administration
            </Typography>


            <Item
              title="Customers"
              to="/customers"
              icon={<DomainIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Users"
              to="/users"
              icon={<PeopleIcon />}
              selected={selected}
              setSelected={setSelected}
            />


          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
