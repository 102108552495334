import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import SecurityIcon from '@mui/icons-material/Security';
import BugReportIcon from '@mui/icons-material/BugReport';
import ElectricalServicesSharpIcon from '@mui/icons-material/ElectricalServicesSharp';
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import AppsOutageIcon from '@mui/icons-material/AppsOutage';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import { DataGrid } from "@mui/x-data-grid";
import { mockDataInvoices } from "../../data/mockData";
import TerminalIcon from '@mui/icons-material/Terminal';
import WindowIcon from '@mui/icons-material/Window';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PieChart from "../../components/PieChart";




export const detailsTableData = [
  {
      "id": "1a2b3c",
      "agent": "siem", 
      "count": 535, 
      "description": 5, 
      "requirement": "AC.7"

  },
  {
      "id": "4d5e6f",
      "agent": "siem", 
      "count": 11, 
      "description": 3, 
      "requirement": "AC.7"
  },
  {
      "id": "7g8h9i",
      "agent": "siem", 
      "count": 6, 
      "description": 10, 
      "requirement": "AC.7"
  },
  {
      "id": "j1k2l3",
      "agent": "siem", 
      "count": 535, 
      "description": 5, 
      "requirement": "AU.14"
  },
  {
      "id": "m4n5o6",
      "agent": "siem", 
      "count": 11, 
      "description": 3, 
      "requirement": "AU.14"
  },
  {
      "id": "p7q8r9",
      "agent": "siem", 
      "count": 6, 
      "description": 10, 
      "requirement": "AU.14"
  },
  {
      "id": "s1t2u3",
      "agent": "siem", 
      "count": 517, 
      "description": 5, 
      "requirement": "AU.6"
  },
  {
      "id": "v4w5x6",
      "agent": "siem", 
      "count": 34, 
      "description": 6, 
      "requirement": "SI.4"
  },
  {
      "id": "y7z8a9",
      "agent": "siem", 
      "count": 6, 
      "description": 10, 
      "requirement": "SI.4"
  },
  {
      "id": "b1c2d3",
      "agent": "siem", 
      "count": 3, 
      "description": 8, 
      "requirement": "SI.4"
  },
];




export const EvolutionLineData = [
  {
      id: "Requirements",
      color: tokens("dark").greenAccent[500],
      data: [
          {
              x: "2023-06-17T08:00:00",
              y: 556,
          },
          {
              x: "2023-06-17T12:00:00",
              y: 552,
          },
          {
              x: "2023-06-17T16:00:00",
              y: 560,
          },
          {
              x: "2023-06-18T08:00:00",
              y: 500,
          },
          {
              x: "2023-06-18T12:00:00",
              y: 0,
          },
          {
              x: "2023-06-18T16:00:00",
              y: 0,
          },
          {
              x: "2023-06-19T08:00:00",
              y: 88,
          },
          {
              x: "2023-06-19T12:00:00",
              y: 232,
          },
          {
              x: "2023-06-19T16:00:00",
              y: 281,
          },
          {
              x: "2023-06-20T08:00:00",
              y: 1,
          },
          {
              x: "2023-06-20T12:00:00",
              y: 35,
          },
          {
              x: "2023-06-20T16:00:00",
              y: 14,
          },
      ],
  }
]


export const topLevelLineData = [
  {
      id: "AC.7",
      color: tokens("dark").greenAccent[500],
      data: [
          {
              x: "2023-06-17T08:00:00",
              y: 101,
          },
          {
              x: "2023-06-17T12:00:00",
              y: 75,
          },
          {
              x: "2023-06-17T16:00:00",
              y: 36,
          },
          {
              x: "2023-06-18T08:00:00",
              y: 216,
          },
          {
              x: "2023-06-18T12:00:00",
              y: 35,
          },
          {
              x: "2023-06-18T16:00:00",
              y: 236,
          },
          {
              x: "2023-06-19T08:00:00",
              y: 88,
          },
          {
              x: "2023-06-19T12:00:00",
              y: 232,
          },
          {
              x: "2023-06-19T16:00:00",
              y: 281,
          },
          {
              x: "2023-06-20T08:00:00",
              y: 1,
          },
          {
              x: "2023-06-20T12:00:00",
              y: 35,
          },
          {
              x: "2023-06-20T16:00:00",
              y: 14,
          },
      ],
  }


  ,
  {
    id: "AU.14",
    color: tokens("dark").redAccent[500],
    data: [
        {
            x: "2024-06-17",
            y: 6,
        },
        {
            x: "2024-06-18",
            y: 0,
        },
        {
            x: "2024-06-19",
            y: 2,
        },
        {
            x: "2024-06-20",
            y: 0,
        },
        {
            x: "2024-06-21",
            y: 50,
        },
        {
            x: "2024-06-22",
            y: 0,
        },
        {
          x: "2024-06-23",
          y: 700,
       },

       
    ],
}

,
{
  id: "SI.4",
  color: tokens("dark").blueAccent[500],
  data: [
      {
          x: "2024-06-17",
          y: 6,
      },
      {
          x: "2024-06-18",
          y: 0,
      },
      {
          x: "2024-06-19",
          y: 2,
      },
      {
          x: "2024-06-20",
          y: 0,
      },
      {
          x: "2024-06-21",
          y: 50,
      },
      {
          x: "2024-06-22",
          y: 0,
      },
      {
        x: "2024-06-23",
        y: 0,
     },

     
  ],
}

,
{
  id: "AU.6",
  color: tokens("dark").primary[500],
  data: [
      {
          x: "2024-06-17",
          y: 200,
      },
      {
          x: "2024-06-18",
          y: 0,
      },
      {
          x: "2024-06-19",
          y: 2,
      },
      {
          x: "2024-06-20",
          y: 0,
      },
      {
          x: "2024-06-21",
          y: 50,
      },
      {
          x: "2024-06-22",
          y: 0,
      },
      {
        x: "2024-06-23",
        y: 0,
     },

     
  ],
}


];


export const mockLineData = [
  {
      id: "AU.6",
      color: tokens("dark").redAccent[600],
      data: [
          {
              x: "2024-06-17",
              y: 552,
          },
          {
              x: "2024-06-18",
              y: 552,
          },
          {
              x: "2024-06-19",
              y: 552,
          },
          {
              x: "2024-06-20",
              y: 0,
          },
          {
              x: "2024-06-21",
              y: 500,
          },
          {
              x: "2024-06-22",
              y: 0,
          },
          {
            x: "2024-06-23",
            y: 0,
         },

         
      ],
  }
  ,
  {
    id: "SI.4",
    color: tokens("dark").redAccent[500],
    data: [
        {
            x: "2024-06-17",
            y: 0,
        },
        {
            x: "2024-06-18",
            y: 0,
        },
        {
            x: "2024-06-19",
            y: 0,
        },
        {
            x: "2024-06-20",
            y: 0,
        },
        {
            x: "2024-06-21",
            y: 0,
        },
        {
            x: "2024-06-22",
            y: 0,
        },
        {
          x: "2024-06-23",
          y: 0,
       },

       
    ],
}
,
{
    id: "AC.7",
    color: tokens("dark").redAccent[400],
    data: [
        {
            x: "2024-06-17",
            y: 3,
        },
        {
            x: "2024-06-18",
            y: 0,
        },
        {
            x: "2024-06-19",
            y: 8,
        },
        {
            x: "2024-06-20",
            y: 8,
        },
        {
            x: "2024-06-21",
            y: 0,
        },
        {
            x: "2024-06-22",
            y: 0,
        },
        {
          x: "2024-06-23",
          y: 0,
       },

       
    ],
}
,
{
    id: "SI.7",
    color: tokens("dark").redAccent[300],
    data: [
        {
            x: "2024-06-17",
            y: 1,
        },
        {
            x: "2024-06-18",
            y: 0,
        },
        {
            x: "2024-06-19",
            y: 0,
        },
        {
            x: "2024-06-20",
            y: 0,
        },
        {
            x: "2024-06-21",
            y: 0,
        },
        {
            x: "2024-06-22",
            y: 0,
        },
        {
          x: "2024-06-23",
          y: 0,
       },

       
    ],
}
];


export const topAgentDistribution = [
  {
    id: "siem",
    label: "siem",
    value: 603,
    color: "hsl(344, 70%, 50%)",
  },
];


export const topRequirementstDistribution = [
  {
    id: "AC.7",
    label: "AC.7",
    value: 552,
    color: "hsl(344, 70%, 50%)",
  },
  {
    id: "AU.14",
    label: "AU.14",
    value: 552, 
    color: "hsl(344, 70%, 50%)",
  },
  {
    id: "AU.6",
    label: "AU.6",
    value: 43,
    color: "hsl(344, 70%, 50%)",
  },
  {
    id: "SI.4",
    label: "SI.4",
    value: 42,
    color: "hsl(344, 70%, 50%)",
  },
  {
    id: "SI.7",
    label: "SI.7",
    value: 2,
    color: "hsl(344, 70%, 50%)",
  },
  {
    id: "AU.9",
    label: "AU.9",
    value: 517,
    color: "hsl(344, 70%, 50%)",
  }
];



export const BarData = [
  {
      country: "2024-06-17",
      "AC.7": 6,
      "AC.7Color": "hsl(207, 70%, 50%)", // Replace with actual color
      "AU.14": 0,
      "AU.14Color": "hsl(326, 70%, 50%)", // Replace with actual color
      "SI.4": 6,
      "SI.4Color": "hsl(296, 70%, 50%)", // Replace with actual color
      "AU.6": 200,
      "AU.6Color": "hsl(97, 70%, 50%)", // Replace with actual color
  },
  {
      country: "2024-06-18",
      "AC.7": 0,
      "AC.7Color": "hsl(207, 70%, 50%)", // Replace with actual color
      "AU.14": 0,
      "AU.14Color": "hsl(326, 70%, 50%)", // Replace with actual color
      "SI.4": 0,
      "SI.4Color": "hsl(296, 70%, 50%)", // Replace with actual color
      "AU.6": 0,
      "AU.6Color": "hsl(97, 70%, 50%)", // Replace with actual color
  },
  {
      country: "2024-06-19",
      "AC.7": 2,
      "AC.7Color": "hsl(207, 70%, 50%)", // Replace with actual color
      "AU.14": 2,
      "AU.14Color": "hsl(326, 70%, 50%)", // Replace with actual color
      "SI.4": 2,
      "SI.4Color": "hsl(296, 70%, 50%)", // Replace with actual color
      "AU.6": 2,
      "AU.6Color": "hsl(97, 70%, 50%)", // Replace with actual color
  },
  {
      country: "2024-06-20",
      "AC.7": 0,
      "AC.7Color": "hsl(207, 70%, 50%)", // Replace with actual color
      "AU.14": 0,
      "AU.14Color": "hsl(326, 70%, 50%)", // Replace with actual color
      "SI.4": 0,
      "SI.4Color": "hsl(296, 70%, 50%)", // Replace with actual color
      "AU.6": 0,
      "AU.6Color": "hsl(97, 70%, 50%)", // Replace with actual color
  },
  {
      country: "2024-06-21",
      "AC.7": 50,
      "AC.7Color": "hsl(207, 70%, 50%)", // Replace with actual color
      "AU.14": 50,
      "AU.14Color": "hsl(326, 70%, 50%)", // Replace with actual color
      "SI.4": 50,
      "SI.4Color": "hsl(296, 70%, 50%)", // Replace with actual color
      "AU.6": 50,
      "AU.6Color": "hsl(97, 70%, 50%)", // Replace with actual color
  },
  {
      country: "2024-06-22",
      "AC.7": 0,
      "AC.7Color": "hsl(207, 70%, 50%)", // Replace with actual color
      "AU.14": 0,
      "AU.14Color": "hsl(326, 70%, 50%)", // Replace with actual color
      "SI.4": 0,
      "SI.4Color": "hsl(296, 70%, 50%)", // Replace with actual color
      "AU.6": 0,
      "AU.6Color": "hsl(97, 70%, 50%)", // Replace with actual color
  },
  {
      country: "2024-06-23",
      "AC.7": 700,
      "AC.7Color": "hsl(207, 70%, 50%)", // Replace with actual color
      "AU.14": 700,
      "AU.14Color": "hsl(326, 70%, 50%)", // Replace with actual color
      "SI.4": 0,
      "SI.4Color": "hsl(296, 70%, 50%)", // Replace with actual color
      "AU.6": 0,
      "AU.6Color": "hsl(97, 70%, 50%)", // Replace with actual color
  },
];


export const paramlist = ["AC.7", "AU.14", "SI.4", "AU.6"];

const NistDashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);



  const columns = [
    { field: "id", headerName: "Rule ID" },
    {
      field: "agent",
      headerName: "Agent",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "requirement",
      headerName: "Requirement",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },

    {
      field: "count",
      headerName: "Count",
      flex: 1,
    },
  ];





  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="NIST 800-53" subtitle="Adhere to NIST Controls." />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>

      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="0"
            subtitle="Windows Requirements"
            progress="0.75"
            increase="+14%"
            icon={
              <WindowIcon 
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="1164"
            subtitle="Linux Requirements"
            progress="0.50"
            increase="+21%"
            icon={
              <TerminalIcon 
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="1164"
            subtitle="Total Requirements"
            progress="0.30"
            increase="+5%"
            icon={
              <AssignmentIcon  
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Control Level Evolution
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart isDashboard={true} dataset={mockLineData} />
          </Box>
        </Box>


        {/* ROW 2 */}
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Requirements Evolution
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart isDashboard={true} dataset={EvolutionLineData} />
          </Box>
        </Box>







        {/* ROW 3 */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Top Agents
          </Typography>

            
                       
            <Box height="33vh">
                <PieChart pData={topAgentDistribution} />
            </Box>


        </Box>

        {/* ROW 3 */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Top Requirements
          </Typography>

            
                       
            <Box height="33vh">
                <PieChart pData={topRequirementstDistribution} />
            </Box>


        </Box>



         {/* ROW 3 */}
         <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Requirements Evolution
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} srvData={BarData} paramKeys={paramlist} />
          </Box>
        </Box>




         {/* ROW 4 */}
         <Box
         gridColumn="span 12"
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid checkboxSelection rows={detailsTableData} columns={columns} />
      </Box>
         {/* ROW 5 END */}

      </Box>


    </Box>
  );
};

export default NistDashboard;
