import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
//import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import SecurityIcon from '@mui/icons-material/Security';
import BugReportIcon from '@mui/icons-material/BugReport';
import ElectricalServicesSharpIcon from '@mui/icons-material/ElectricalServicesSharp';
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import AppsOutageIcon from '@mui/icons-material/AppsOutage';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import { DataGrid } from "@mui/x-data-grid";
import { mockDataInvoices } from "../../data/mockData";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import WarningIcon from '@mui/icons-material/Warning';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import ReportIcon from '@mui/icons-material/Report';
import PieChart from "../../components/PieChart";
import { useParams } from 'react-router-dom';
import PhonelinkOffIcon from '@mui/icons-material/PhonelinkOff';
import { useNavigate } from 'react-router-dom';




export const ComplianceData = [
    {
      txId: "GDPR",
      user: "135",

      cost: "PCI DSS",
    },
    {
      txId: "NIST 800-53",
      user: "65",

      cost: "Details",
    },
    {
      txId: "PCI DSS",
      user: "57",

      cost: "Details",
    },

  ];


export const mitreData = [
    {
      txId: "Password Guessing",
      user: "263",

      cost: "Details",
    },
    {
      txId: "Brute Force",
      user: "4",

      cost: "Details",
    },
    {
      txId: "SSH",
      user: "124",

      cost: "Details",
    },

  ];



export const malwareData = [
    {
      txId: "RansomeWare",
      user: "High",
      date: "2021-09-01",
      cost: "Details",
    },
    {
      txId: "HorseTrojan",
      user: "High",
      date: "2022-04-01",
      cost: "Details",
    },
    {
      txId: "Broken Registery",
      user: "Low",
      date: "2021-09-01",
      cost: "Details",
    },

  ];




export const CasesData = [
    {
      txId: "01e4dsa",
      user: "DDoS",
      date: "2021-09-01",
      cost: "Details",
    },
    {
      txId: "0315dsaa",
      user: "Auth Failures",
      date: "2022-04-01",
      cost: "Details",
    },
    {
      txId: "01e4dsa",
      user: "Root User Activated",
      date: "2021-09-01",
      cost: "Details",
    },

  ];

export const mockTransactions = [
    {
      txId: "CVE-2021-29468",
      user: "6.6",
      date: "May 21, 2024 @ 01:32:05.000",
      cost: "High",
    },
    {
      txId: "CVE-2023-29007",
      user: "7.8",
      date: "May 21, 2024 @ 01:32:05.000",
      cost: "High",
    },
    {
      txId: "CVE-2023-25652",
      user: "7.5",
      date: "May 21, 2024 @ 01:32:06.000",
      cost: "High",
    },
   
  ];


export const fimTableData = [
    {   
        id : 1545,
        action: "modified", 
        agent: "siem", 
        count: "19", 
        path: "/etc/hosts"
      }, 
      {
        id : 3215,
        action: "modified", 
        agent: "siem", 
        count: "19", 
        path: "/etc/resolv.conf"
      }, 
      { id : 21,
        action: "modified", 
        agent: "siem", 
        count: "15", 
        path: "/etc/group"
      }, 
      { id : 59,
        action: "modified", 
        agent: "siem", 
        count: "15", 
        path: "/etc/group-"
      }, 
      { id : 64,
        action: "modified", 
        agent: "siem", 
        count: "15", 
        path: "/etc/gshadow"
      }, 
      { id : 452,
        action: "modified", 
        agent: "siem", 
        count: "15", 
        path: "/etc/gshadow-"
      },
  ];



export const mockMostCommonCVEs = [
    {
      id: "CVE-2024-0397",
      label: "CVE-2024-0397",
      value: 8,
      color: "hsl(104, 70%, 50%)",
    },
    {
      id: "CVE-2024-4032",
      label: "CVE-2024-4032",
      value: 8,
      color: "hsl(162, 70%, 50%)",
    },
    {
      id: "CVE-2024-35325",
      label: "CVE-2024-35325",
      value: 1,
      color: "hsl(291, 70%, 50%)",
    },
    {
      id: "CVE-2024-35326",
      label: "CVE-2024-35326",
      value: 1,
      color: "hsl(229, 70%, 50%)",
    },
    {
      id: "CVE-2024-35328",
      label: "CVE-2024-35328",
      value: 1,
      color: "hsl(344, 70%, 50%)",
    },
  ];


export const mockPieData = [
    {
      id: "libpython3.8-minimal",
      label: "libpython3.8-minimal",
      value: 4,
      color: "hsl(104, 70%, 50%)",
    },
    {
      id: "libpython3.8-stdlib",
      label: "libpython3.8-stdlib",
      value: 4,
      color: "hsl(162, 70%, 50%)",
    },
    {
      id: "python3.8",
      label: "python3.8",
      value: 4,
      color: "hsl(291, 70%, 50%)",
    },
    {
      id: "python3.8-minimal",
      label: "python3.8-minimal",
      value: 4,
      color: "hsl(229, 70%, 50%)",
    },
    {
      id: "libyaml-0-2",
      label: "libyaml-0-2",
      value: 3,
      color: "hsl(344, 70%, 50%)",
    },
  ];
  


  export const mockSeverityDistribution = [
    {
      id: "Medium",
      label: "Medium",
      value: 19,
      color: "hsl(291, 70%, 50%)",
    },
    {
      id: "Low",
      label: "Low",
      value: 1,
      color: "hsl(344, 70%, 50%)",
    },
  ];






export const commonAffectedPackages = [
    {
      "name": "libpython3.8-minimal",
      "value": 4
    },
    {
      "name": "libpython3.8-stdlib",
      "value": 4
    },
    {
      "name": "python3.8",
      "value": 4
    },
    {
      "name": "python3.8-minimal",
      "value": 4
    },
    {
      "name": "libyaml-0-2",
      "value": 3
    }
  ];
  
  export const paramlist = ["added", "removed", "modified"]


export const detailsTableData = [
    {
        "id": "1",
        "policy": "Ubuntu OS lInux BenchMark v1.0.0",
        "endScan": "5-2-2024 003541",
        "passed": "77",
        "failed": "103",
        "score": "42%"
    },
   
  ];


export const mockLineData = [
  {
      id: "Critical",
      color: tokens("dark").redAccent[600],
      data: [
          {
              x: "2024-06-17",
              y: 0,
          },
          {
              x: "2024-06-18",
              y: 0,
          },
          {
              x: "2024-06-19",
              y: 0,
          },
          {
              x: "2024-06-20",
              y: 0,
          },
          {
              x: "2024-06-21",
              y: 0,
          },
          {
              x: "2024-06-22",
              y: 0,
          },
          {
            x: "2024-06-23",
            y: 0,
         },

         
      ],
  }
  ,
  {
    id: "High",
    color: tokens("dark").redAccent[500],
    data: [
        {
            x: "2024-06-17",
            y: 0,
        },
        {
            x: "2024-06-18",
            y: 0,
        },
        {
            x: "2024-06-19",
            y: 0,
        },
        {
            x: "2024-06-20",
            y: 0,
        },
        {
            x: "2024-06-21",
            y: 0,
        },
        {
            x: "2024-06-22",
            y: 0,
        },
        {
          x: "2024-06-23",
          y: 0,
       },

       
    ],
}
,
{
    id: "Medium",
    color: tokens("dark").redAccent[400],
    data: [
        {
            x: "2024-06-17",
            y: 3,
        },
        {
            x: "2024-06-18",
            y: 0,
        },
        {
            x: "2024-06-19",
            y: 8,
        },
        {
            x: "2024-06-20",
            y: 8,
        },
        {
            x: "2024-06-21",
            y: 0,
        },
        {
            x: "2024-06-22",
            y: 0,
        },
        {
          x: "2024-06-23",
          y: 0,
       },

       
    ],
}
,
{
    id: "Low",
    color: tokens("dark").redAccent[300],
    data: [
        {
            x: "2024-06-17",
            y: 1,
        },
        {
            x: "2024-06-18",
            y: 0,
        },
        {
            x: "2024-06-19",
            y: 0,
        },
        {
            x: "2024-06-20",
            y: 0,
        },
        {
            x: "2024-06-21",
            y: 0,
        },
        {
            x: "2024-06-22",
            y: 0,
        },
        {
          x: "2024-06-23",
          y: 0,
       },

       
    ],
}
];


export const mockBarData = [
    {
      country: "2024-06-17",
      added: 137,
      addedColor: "hsl(229, 70%, 50%)",
      removed: 96,
      removedColor: "hsl(296, 70%, 50%)",
      modified: 72,
      modifiedColor: "hsl(97, 70%, 50%)",
    },
    {
      country: "2024-06-18",
      added: 55,
      addedColor: "hsl(307, 70%, 50%)",
      removed: 28,
      removedColor: "hsl(111, 70%, 50%)",
      modified: 58,
      modifiedColor: "hsl(273, 70%, 50%)",

    },
    {
      country: "2024-06-19",
      added: 109,
      addedColor: "hsl(72, 70%, 50%)",
      removed: 23,
      removedColor: "hsl(96, 70%, 50%)",
      modified: 34,
      modifiedColor: "hsl(106, 70%, 50%)",

    },
    {
      country: "2024-06-20",
      added: 133,
      addedColor: "hsl(257, 70%, 50%)",
      removed: 52,
      removedColor: "hsl(326, 70%, 50%)",
      modified: 43,
      modifiedColor: "hsl(110, 70%, 50%)",

    },
    {
        country: "2024-06-21",
        added: 433,
        addedColor: "hsl(257, 70%, 50%)",
        removed: 531,
        removedColor: "hsl(326, 70%, 50%)",
        modified: 54,
        modifiedColor: "hsl(110, 70%, 50%)",
  
      },
      {
        country: "2024-06-22",
        added: 10,
        addedColor: "hsl(257, 70%, 50%)",
        removed: 52,
        removedColor: "hsl(326, 70%, 50%)",
        modified: 700,
        modifiedColor: "hsl(110, 70%, 50%)",
  
      },
      {
        country: "2024-06-23",
        added: 150,
        addedColor: "hsl(257, 70%, 50%)",
        removed: 52,
        removedColor: "hsl(326, 70%, 50%)",
        modified: 201,
        modifiedColor: "hsl(110, 70%, 50%)",
  
      },
      
  ];






const AgentLevelView = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams(); 
  const agentID = "Agent-" + id

  const navigate = useNavigate();

  const statsHandleClick = () => {
    navigate(`/agentStats/${id}`);
  };

  const inventoryHandleClick = () => {
    navigate(`/agentInventory/${id}`);
  };


  const fimColumns = [
    { field: "id", headerName: "ID" },
    {
        field: "action",
        headerName: "Action",
        flex: 1,
        cellClassName: "name-column--cell",
      },

    
    {
      field: "count",
      headerName: "Count",
      flex: 1,
    },
    {
      field: "path",
      headerName: "Path",
      flex: 1,
    },
  ];
  

  const scaColumns = [
    { field: "id", headerName: "ID" },
    {
      field: "policy",
      headerName: "Policy",
      flex: 1,
    },
    {
        field: "endScan",
        headerName: "End Scan",
        flex: 1,
      },
    {
      field: "passed",
      headerName: "Passed",
      flex: 1,
    },

    {
      field: "failed",
      headerName: "Failed",
      flex: 1,
    },
    {
        field: "score",
        headerName: "Score",
        flex: 1,
      },
  ];






  const columns = [
    { field: "id", headerName: "Rule ID" },
    {
      field: "name",
      headerName: "Description",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "phone",
      headerName: "Severity Level",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Count",
      flex: 1,
    },

    {
      field: "date",
      headerName: "Date",
      flex: 1,
    },
  ];





  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={agentID} subtitle="" />

        <Box   >
        <Button
            onClick={statsHandleClick}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            
            Stats
          </Button>
          |
          <Button
            onClick={inventoryHandleClick}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            
            Inventory
          </Button>
           | 
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            
            Quarantine
          </Button>
          | 
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            
            Active Response
          </Button>
          | 
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            
            Command
          </Button>
          


        </Box>

      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="Disconnected"
            subtitle="Status"
            progress="0"
            increase="+0%"
            icon={
              <RunningWithErrorsIcon 
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="3"
            subtitle="Cases"
            progress="0"
            increase="+0%"
            icon={
              <CrisisAlertIcon 
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="9"
            subtitle="Vulnerabilities"
            progress="1"
            increase="+100%"
            icon={
              <ReportIcon   
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="823"
            subtitle="Alerts"
            progress="0"
            increase="+0%"
            icon={
              <WarningIcon   
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>




        {/* ROW 3 */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Vulnerabilities
            </Typography>
          </Box>
          {mockTransactions.map((transaction, i) => (
            <Box
              key={`${transaction.txId}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {transaction.txId}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {transaction.user}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{transaction.date}</Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                {transaction.cost}
              </Box>
            </Box>
          ))}
        </Box>


        {/* ROW 3 */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        
          
        height="37vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            
            
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}

        >

           <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              FIM Recent Events
            </Typography>
          </Box>

        <DataGrid checkboxSelection rows={fimTableData} columns={fimColumns} rowHeight={25} headerHeight={25}  />
        </Box>


        {/* ROW 3 */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Cases
            </Typography>
          </Box>
          {CasesData.map((transaction, i) => (
            <Box
              key={`${transaction.txId}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {transaction.txId}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {transaction.user}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{transaction.date}</Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                {transaction.cost}
              </Box>
            </Box>
          ))}
        </Box>




        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        
          
        height="37vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            
            
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}

        >
            


            <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
            SCA Latest Scans
            </Typography>
          </Box>

        <DataGrid checkboxSelection rows={detailsTableData} columns={scaColumns} rowHeight={25} headerHeight={25}  />
        </Box>

        {/* ROW 4 */}

        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Malware Detections
            </Typography>
          </Box>
          {malwareData.map((transaction, i) => (
            <Box
              key={`${transaction.txId}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {transaction.txId}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {transaction.user}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{transaction.date}</Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                {transaction.cost}
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              MITRE 
            </Typography>
          </Box>
          {mitreData.map((transaction, i) => (
            <Box
              key={`${transaction.txId}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {transaction.txId}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {transaction.user}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{transaction.date}</Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                {transaction.cost}
              </Box>
            </Box>
          ))}
        </Box>

        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Compliance 
            </Typography>
          </Box>
          {ComplianceData.map((transaction, i) => (
            <Box
              key={`${transaction.txId}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {transaction.txId}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {transaction.user}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{transaction.date}</Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                {transaction.cost}
              </Box>
            </Box>
          ))}
        </Box>

        {/* end of ROW 4 */}


         {/* ROW 5 */}
         <Box
         gridColumn="span 12"
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid checkboxSelection rows={mockDataInvoices} columns={columns} />
      </Box>
         {/* ROW 5 END */}

      </Box>


    </Box>
  );
};

export default AgentLevelView;
