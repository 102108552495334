import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import SecurityIcon from '@mui/icons-material/Security';
import BugReportIcon from '@mui/icons-material/BugReport';
import ElectricalServicesSharpIcon from '@mui/icons-material/ElectricalServicesSharp';
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import AppsOutageIcon from '@mui/icons-material/AppsOutage';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import { DataGrid } from "@mui/x-data-grid";
import { mockDataInvoices } from "../../data/mockData";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import WarningIcon from '@mui/icons-material/Warning';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import ReportIcon from '@mui/icons-material/Report';
import PieChart from "../../components/PieChart";
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import PhonelinkOffIcon from '@mui/icons-material/PhonelinkOff';
import { Link } from 'react-router-dom';





export const mockMostCommonCVEs = [
    {
      id: "CVE-2024-0397",
      label: "CVE-2024-0397",
      value: 8,
      color: "hsl(104, 70%, 50%)",
    },
    {
      id: "CVE-2024-4032",
      label: "CVE-2024-4032",
      value: 8,
      color: "hsl(162, 70%, 50%)",
    },
    {
      id: "CVE-2024-35325",
      label: "CVE-2024-35325",
      value: 1,
      color: "hsl(291, 70%, 50%)",
    },
    {
      id: "CVE-2024-35326",
      label: "CVE-2024-35326",
      value: 1,
      color: "hsl(229, 70%, 50%)",
    },
    {
      id: "CVE-2024-35328",
      label: "CVE-2024-35328",
      value: 1,
      color: "hsl(344, 70%, 50%)",
    },
  ];


export const mockPieData = [
    {
      id: "libpython3.8-minimal",
      label: "libpython3.8-minimal",
      value: 4,
      color: "hsl(104, 70%, 50%)",
    },
    {
      id: "libpython3.8-stdlib",
      label: "libpython3.8-stdlib",
      value: 4,
      color: "hsl(162, 70%, 50%)",
    },
    {
      id: "python3.8",
      label: "python3.8",
      value: 4,
      color: "hsl(291, 70%, 50%)",
    },
    {
      id: "python3.8-minimal",
      label: "python3.8-minimal",
      value: 4,
      color: "hsl(229, 70%, 50%)",
    },
    {
      id: "libyaml-0-2",
      label: "libyaml-0-2",
      value: 3,
      color: "hsl(344, 70%, 50%)",
    },
  ];
  


  export const mockSeverityDistribution = [
    {
      id: "Active",
      label: "Active",
      value: 0,
      color: "hsl(291, 70%, 50%)",
    },
    {
      id: "Disconnected",
      label: "Disconnected",
      value: 2,
      color: "hsl(344, 70%, 50%)",
    },

    {
        id: "Never Connected",
        label: "Never Connected",
        value: 0,
        color: "hsl(291, 70%, 50%)",
      },

  ];






export const commonAffectedPackages = [
    {
      "name": "libpython3.8-minimal",
      "value": 4
    },
    {
      "name": "libpython3.8-stdlib",
      "value": 4
    },
    {
      "name": "python3.8",
      "value": 4
    },
    {
      "name": "python3.8-minimal",
      "value": 4
    },
    {
      "name": "libyaml-0-2",
      "value": 3
    }
  ];
  
  export const paramlist = ["added", "removed", "modified"]


export const detailsTableData = [
    {
        "id": "001",
        "name": "ssdnodes-5fc64cade3a62",
        "ip-address": "1:2.20.1-2+deb10u8",
        "group": "Linux_dev",
        "operating-system": "Ubuntu 22.04.4 LTS",
        "version": "v4.7.4",
        "status": "down"
    },
    {
        "id": "003",
        "name": "siem",
        "ip-address": "10.182.0.5",
        "group": "Linux_dev",
        "operating-system": "Debian GNU/Linux 10",
        "version": "v4.7.4",
        "status": "down"
    },

  ];


export const mockLineData = [
  {
      id: "Never-Conncted",
      color: tokens("dark").redAccent[600],
      data: [
          {
              x: "2024-06-17",
              y: 0,
          },
          {
              x: "2024-06-18",
              y: 0,
          },
          {
              x: "2024-06-19",
              y: 0,
          },
          {
              x: "2024-06-20",
              y: 0,
          },
          {
              x: "2024-06-21",
              y: 0,
          },
          {
              x: "2024-06-22",
              y: 0,
          },
          {
            x: "2024-06-23",
            y: 0,
         },

         
      ],
  }
  ,
  {
    id: "Disconnected",
    color: tokens("dark").redAccent[500],
    data: [
        {
            x: "2024-06-17",
            y: 2,
        },
        {
            x: "2024-06-18",
            y: 2,
        },
        {
            x: "2024-06-19",
            y: 2,
        },
        {
            x: "2024-06-20",
            y: 2,
        },
        {
            x: "2024-06-21",
            y: 2,
        },
        {
            x: "2024-06-22",
            y: 2,
        },
        {
          x: "2024-06-23",
          y: 2,
       },

       
    ],
}
,

];


export const mockBarData = [
    {
      country: "2024-06-17",
      added: 137,
      addedColor: "hsl(229, 70%, 50%)",
      removed: 96,
      removedColor: "hsl(296, 70%, 50%)",
      modified: 72,
      modifiedColor: "hsl(97, 70%, 50%)",
    },
    {
      country: "2024-06-18",
      added: 55,
      addedColor: "hsl(307, 70%, 50%)",
      removed: 28,
      removedColor: "hsl(111, 70%, 50%)",
      modified: 58,
      modifiedColor: "hsl(273, 70%, 50%)",

    },
    {
      country: "2024-06-19",
      added: 109,
      addedColor: "hsl(72, 70%, 50%)",
      removed: 23,
      removedColor: "hsl(96, 70%, 50%)",
      modified: 34,
      modifiedColor: "hsl(106, 70%, 50%)",

    },
    {
      country: "2024-06-20",
      added: 133,
      addedColor: "hsl(257, 70%, 50%)",
      removed: 52,
      removedColor: "hsl(326, 70%, 50%)",
      modified: 43,
      modifiedColor: "hsl(110, 70%, 50%)",

    },
    {
        country: "2024-06-21",
        added: 433,
        addedColor: "hsl(257, 70%, 50%)",
        removed: 531,
        removedColor: "hsl(326, 70%, 50%)",
        modified: 54,
        modifiedColor: "hsl(110, 70%, 50%)",
  
      },
      {
        country: "2024-06-22",
        added: 10,
        addedColor: "hsl(257, 70%, 50%)",
        removed: 52,
        removedColor: "hsl(326, 70%, 50%)",
        modified: 700,
        modifiedColor: "hsl(110, 70%, 50%)",
  
      },
      {
        country: "2024-06-23",
        added: 150,
        addedColor: "hsl(257, 70%, 50%)",
        removed: 52,
        removedColor: "hsl(326, 70%, 50%)",
        modified: 201,
        modifiedColor: "hsl(110, 70%, 50%)",
  
      },
      
  ];



const AgentsDashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);



  const columns = [
    { field: "id", headerName: "ID" ,

        renderCell: (params) => (
            <Link to={`/agentsDetails/${params.value}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                {params.value}
            </Link>
        )
     },
    {
        field: "name",
        headerName: "Name",
        flex: 1,
        cellClassName: "name-column--cell",
      },

    {
      field: "ip-address",
      headerName: "IP Address",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "group",
      headerName: "Group",
      flex: 1,
    },
    {
      field: "operating-system",
      headerName: "Operating System",
      flex: 1,
    },

    {
        field: "version",
        headerName: "Version",
        flex: 1,
      },

      {
        field: "status",
        headerName: "Status",
        flex: 1,
      }
  ];





  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="AGENTS" subtitle="" />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>

      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="2"
            subtitle="Total Agents"
            progress="0"
            increase="+0%"
            icon={
              <DevicesOtherIcon 
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="0"
            subtitle="Active"
            progress="0"
            increase="+0%"
            icon={
              <PhonelinkIcon  
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="2"
            subtitle="Disconnected"
            progress="1"
            increase="+100%"
            icon={
              <PhonelinkOffIcon   
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="0"
            subtitle="Never Connected"
            progress="0"
            increase="+0%"
            icon={
              <SyncProblemIcon   
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>




        {/* ROW 3 */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Agents Distribution
          </Typography>

            
                       
            <Box height="33vh">
                <PieChart pData={mockSeverityDistribution} />
            </Box>


        </Box>


        {/* ROW 3 */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Agents Evolution
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart isDashboard={true} dataset={mockLineData} />
          </Box>
        </Box>


      



         {/* ROW 4 */}
         <Box
         gridColumn="span 12"
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid checkboxSelection rows={detailsTableData} columns={columns} />
      </Box>
         {/* ROW 5 END */}

      </Box>


    </Box>
  );
};

export default AgentsDashboard;
