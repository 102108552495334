import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import SecurityIcon from '@mui/icons-material/Security';
import BugReportIcon from '@mui/icons-material/BugReport';
import ElectricalServicesSharpIcon from '@mui/icons-material/ElectricalServicesSharp';
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import AppsOutageIcon from '@mui/icons-material/AppsOutage';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import { DataGrid } from "@mui/x-data-grid";
import { mockDataInvoices } from "../../data/mockData";




export const mockLineData = [
  {
      id: "Security Events",
      color: tokens("dark").greenAccent[500],
      data: [
          {
              x: "2023-06-17T08:00:00",
              y: 101,
          },
          {
              x: "2023-06-17T12:00:00",
              y: 75,
          },
          {
              x: "2023-06-17T16:00:00",
              y: 36,
          },
          {
              x: "2023-06-18T08:00:00",
              y: 216,
          },
          {
              x: "2023-06-18T12:00:00",
              y: 35,
          },
          {
              x: "2023-06-18T16:00:00",
              y: 236,
          },
          {
              x: "2023-06-19T08:00:00",
              y: 88,
          },
          {
              x: "2023-06-19T12:00:00",
              y: 232,
          },
          {
              x: "2023-06-19T16:00:00",
              y: 281,
          },
          {
              x: "2023-06-20T08:00:00",
              y: 1,
          },
          {
              x: "2023-06-20T12:00:00",
              y: 35,
          },
          {
              x: "2023-06-20T16:00:00",
              y: 14,
          },
      ],
  }
];



const CorrelationMetricView = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);



  const columns = [
    { field: "id", headerName: "Rule ID" },
    {
      field: "name",
      headerName: "Description",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "phone",
      headerName: "Severity Level",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Count",
      flex: 1,
    },

    {
      field: "date",
      headerName: "Date",
      flex: 1,
    },
  ];





  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="NA" subtitle="" />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>

      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
       

        {/* ROW 2 */}
       
         {/* ROW 5 END */}

      </Box>


    </Box>
  );
};

export default CorrelationMetricView;
