import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import SecurityIcon from '@mui/icons-material/Security';
import BugReportIcon from '@mui/icons-material/BugReport';
import ElectricalServicesSharpIcon from '@mui/icons-material/ElectricalServicesSharp';
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import AppsOutageIcon from '@mui/icons-material/AppsOutage';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import { DataGrid } from "@mui/x-data-grid";
import { mockDataInvoices } from "../../data/mockData";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';



export const detailsTableData = [
    {   
        id : 1545,
        action: "modified", 
        agent: "siem", 
        count: "19", 
        path: "/etc/hosts"
      }, 
      {
        id : 3215,
        action: "modified", 
        agent: "siem", 
        count: "19", 
        path: "/etc/resolv.conf"
      }, 
      { id : 21,
        action: "modified", 
        agent: "siem", 
        count: "15", 
        path: "/etc/group"
      }, 
      { id : 59,
        action: "modified", 
        agent: "siem", 
        count: "15", 
        path: "/etc/group-"
      }, 
      { id : 64,
        action: "modified", 
        agent: "siem", 
        count: "15", 
        path: "/etc/gshadow"
      }, 
      { id : 452,
        action: "modified", 
        agent: "siem", 
        count: "15", 
        path: "/etc/gshadow-"
      },
  ];


export const mockLineData = [
  {
      id: "Change Trend",
      color: tokens("dark").greenAccent[500],
      data: [
          {
              x: "2024-06-17",
              y: 300,
          },
          {
              x: "2024-06-18",
              y: 140,
          },
          {
              x: "2024-06-19",
              y: 180,
          },
          {
              x: "2024-06-20",
              y: 230,
          },
          {
              x: "2024-06-21",
              y: 1020,
          },
          {
              x: "2024-06-22",
              y: 760,
          },
          {
            x: "2024-06-23",
            y: 400,
         },
   
      ],
  }
];


export const mockBarData = [
    {
      country: "2024-06-17",
      added: 137,
      addedColor: "hsl(229, 70%, 50%)",
      removed: 96,
      removedColor: "hsl(296, 70%, 50%)",
      modified: 72,
      modifiedColor: "hsl(97, 70%, 50%)",
    },
    {
      country: "2024-06-18",
      added: 55,
      addedColor: "hsl(307, 70%, 50%)",
      removed: 28,
      removedColor: "hsl(111, 70%, 50%)",
      modified: 58,
      modifiedColor: "hsl(273, 70%, 50%)",

    },
    {
      country: "2024-06-19",
      added: 109,
      addedColor: "hsl(72, 70%, 50%)",
      removed: 23,
      removedColor: "hsl(96, 70%, 50%)",
      modified: 34,
      modifiedColor: "hsl(106, 70%, 50%)",

    },
    {
      country: "2024-06-20",
      added: 133,
      addedColor: "hsl(257, 70%, 50%)",
      removed: 52,
      removedColor: "hsl(326, 70%, 50%)",
      modified: 43,
      modifiedColor: "hsl(110, 70%, 50%)",

    },
    {
        country: "2024-06-21",
        added: 433,
        addedColor: "hsl(257, 70%, 50%)",
        removed: 531,
        removedColor: "hsl(326, 70%, 50%)",
        modified: 54,
        modifiedColor: "hsl(110, 70%, 50%)",
  
      },
      {
        country: "2024-06-22",
        added: 10,
        addedColor: "hsl(257, 70%, 50%)",
        removed: 52,
        removedColor: "hsl(326, 70%, 50%)",
        modified: 700,
        modifiedColor: "hsl(110, 70%, 50%)",
  
      },
      {
        country: "2024-06-23",
        added: 150,
        addedColor: "hsl(257, 70%, 50%)",
        removed: 52,
        removedColor: "hsl(326, 70%, 50%)",
        modified: 201,
        modifiedColor: "hsl(110, 70%, 50%)",
  
      },
      
  ];

export const paramlist = ["added", "removed", "modified"]

const FileIntegrityMonitoring = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);



  const columns = [
    { field: "id", headerName: "ID" },
    {
        field: "action",
        headerName: "Action",
        flex: 1,
        cellClassName: "name-column--cell",
      },

    {
      field: "agent",
      headerName: "Agent",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "count",
      headerName: "Count",
      flex: 1,
    },
    {
      field: "path",
      headerName: "Path",
      flex: 1,
    },
  ];





  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="FILE INTEGRITY MONITORING" subtitle="Alerts related to file changes" />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>

      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="5,551"
            subtitle="Files Modified"
            progress="0.75"
            increase="+14%"
            icon={
              <DriveFileRenameOutlineIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="1,071"
            subtitle="Files Added"
            progress="0.50"
            increase="+21%"
            icon={
              <CreateNewFolderIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="1,502"
            subtitle="Files Removed"
            progress="0.30"
            increase="+5%"
            icon={
              <FolderDeleteIcon  
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>



         {/* ROW 2 */}
         <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Top 5 Evolution
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} srvData={mockBarData} paramKeys={paramlist} />
          </Box>
        </Box>

         {/* ROW 2 end */}

        {/* ROW 3 */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Typography variant="h5" fontWeight="600">
            Top 5 Agents
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            <ProgressCircle size="125" />
            <Typography
              variant="h5"
              color={colors.greenAccent[500]}
              sx={{ mt: "15px" }}
            >
              234 device1, 500 device5, 200 win-sdf
            </Typography>
            <Typography>Distribution by Agents</Typography>
          </Box>
        </Box>


        {/* ROW 3 */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                File Changes Over Time
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart isDashboard={true} dataset={mockLineData} />
          </Box>
        </Box>


        {/* ROW 4 */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Typography variant="h5" fontWeight="600">
            Rules Distribution
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            <ProgressCircle size="125" />
            <Typography
              variant="h5"
              color={colors.greenAccent[500]}
              sx={{ mt: "15px" }}
            >
              834 deleted, 1027 added, 1152 modified
            </Typography>
            <Typography>Distribution by Rule Groups</Typography>
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Actions Distribution
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            <ProgressCircle size="125" />
            <Typography
              variant="h5"
              color={colors.greenAccent[500]}
              sx={{ mt: "15px" }}
            >
              834 deleted, 1027 added, 1152 modified
            </Typography>
            <Typography>Distribution by Action</Typography>
          </Box>
        </Box>


        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Top 5 Users
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            <ProgressCircle size="125" />
            <Typography
              variant="h5"
              color={colors.greenAccent[500]}
              sx={{ mt: "15px" }}
            >
              8 root, 2500 administrator
            </Typography>
            <Typography>Distribution by Top Users</Typography>
          </Box>
        </Box>




         {/* ROW 5 */}
         <Box
         gridColumn="span 12"
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid checkboxSelection rows={detailsTableData} columns={columns} />
      </Box>
         {/* ROW 5 END */}

      </Box>


    </Box>
  );
};

export default FileIntegrityMonitoring;
