import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
//import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import SecurityIcon from '@mui/icons-material/Security';
import BugReportIcon from '@mui/icons-material/BugReport';
import ElectricalServicesSharpIcon from '@mui/icons-material/ElectricalServicesSharp';
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import AppsOutageIcon from '@mui/icons-material/AppsOutage';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import { DataGrid } from "@mui/x-data-grid";
import { mockDataInvoices } from "../../data/mockData";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import WarningIcon from '@mui/icons-material/Warning';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import ReportIcon from '@mui/icons-material/Report';
import PieChart from "../../components/PieChart";
import { useParams } from 'react-router-dom';
import PhonelinkOffIcon from '@mui/icons-material/PhonelinkOff';





export const ComplianceData = [
    {
      txId: "GDPR",
      user: "135",

      cost: "PCI DSS",
    },
    {
      txId: "NIST 800-53",
      user: "65",

      cost: "Details",
    },
    {
      txId: "PCI DSS",
      user: "57",

      cost: "Details",
    },

  ];


export const mitreData = [
    {
      txId: "Password Guessing",
      user: "263",

      cost: "Details",
    },
    {
      txId: "Brute Force",
      user: "4",

      cost: "Details",
    },
    {
      txId: "SSH",
      user: "124",

      cost: "Details",
    },

  ];



export const malwareData = [
    {
      txId: "RansomeWare",
      user: "High",
      date: "2021-09-01",
      cost: "Details",
    },
    {
      txId: "HorseTrojan",
      user: "High",
      date: "2022-04-01",
      cost: "Details",
    },
    {
      txId: "Broken Registery",
      user: "Low",
      date: "2021-09-01",
      cost: "Details",
    },

  ];




export const CasesData = [
    {
      txId: "01e4dsa",
      user: "DDoS",
      date: "2021-09-01",
      cost: "Details",
    },
    {
      txId: "0315dsaa",
      user: "Auth Failures",
      date: "2022-04-01",
      cost: "Details",
    },
    {
      txId: "01e4dsa",
      user: "Root User Activated",
      date: "2021-09-01",
      cost: "Details",
    },

  ];

export const mockTransactions = [
    {
      txId: "CVE-2021-29468",
      user: "6.6",
      date: "May 21, 2024 @ 01:32:05.000",
      cost: "High",
    },
    {
      txId: "CVE-2023-29007",
      user: "7.8",
      date: "May 21, 2024 @ 01:32:05.000",
      cost: "High",
    },
    {
      txId: "CVE-2023-25652",
      user: "7.5",
      date: "May 21, 2024 @ 01:32:06.000",
      cost: "High",
    },
   
  ];


export const fimTableData = [
    {   
        id : 1545,
        action: "modified", 
        agent: "siem", 
        count: "19", 
        path: "/etc/hosts"
      }, 
      {
        id : 3215,
        action: "modified", 
        agent: "siem", 
        count: "19", 
        path: "/etc/resolv.conf"
      }, 
      { id : 21,
        action: "modified", 
        agent: "siem", 
        count: "15", 
        path: "/etc/group"
      }, 
      { id : 59,
        action: "modified", 
        agent: "siem", 
        count: "15", 
        path: "/etc/group-"
      }, 
      { id : 64,
        action: "modified", 
        agent: "siem", 
        count: "15", 
        path: "/etc/gshadow"
      }, 
      { id : 452,
        action: "modified", 
        agent: "siem", 
        count: "15", 
        path: "/etc/gshadow-"
      },
  ];



export const mockMostCommonCVEs = [
    {
      id: "CVE-2024-0397",
      label: "CVE-2024-0397",
      value: 8,
      color: "hsl(104, 70%, 50%)",
    },
    {
      id: "CVE-2024-4032",
      label: "CVE-2024-4032",
      value: 8,
      color: "hsl(162, 70%, 50%)",
    },
    {
      id: "CVE-2024-35325",
      label: "CVE-2024-35325",
      value: 1,
      color: "hsl(291, 70%, 50%)",
    },
    {
      id: "CVE-2024-35326",
      label: "CVE-2024-35326",
      value: 1,
      color: "hsl(229, 70%, 50%)",
    },
    {
      id: "CVE-2024-35328",
      label: "CVE-2024-35328",
      value: 1,
      color: "hsl(344, 70%, 50%)",
    },
  ];


export const mockPieData = [
    {
      id: "libpython3.8-minimal",
      label: "libpython3.8-minimal",
      value: 4,
      color: "hsl(104, 70%, 50%)",
    },
    {
      id: "libpython3.8-stdlib",
      label: "libpython3.8-stdlib",
      value: 4,
      color: "hsl(162, 70%, 50%)",
    },
    {
      id: "python3.8",
      label: "python3.8",
      value: 4,
      color: "hsl(291, 70%, 50%)",
    },
    {
      id: "python3.8-minimal",
      label: "python3.8-minimal",
      value: 4,
      color: "hsl(229, 70%, 50%)",
    },
    {
      id: "libyaml-0-2",
      label: "libyaml-0-2",
      value: 3,
      color: "hsl(344, 70%, 50%)",
    },
  ];
  


  export const mockSeverityDistribution = [
    {
      id: "Medium",
      label: "Medium",
      value: 19,
      color: "hsl(291, 70%, 50%)",
    },
    {
      id: "Low",
      label: "Low",
      value: 1,
      color: "hsl(344, 70%, 50%)",
    },
  ];






export const commonAffectedPackages = [
    {
      "name": "libpython3.8-minimal",
      "value": 4
    },
    {
      "name": "libpython3.8-stdlib",
      "value": 4
    },
    {
      "name": "python3.8",
      "value": 4
    },
    {
      "name": "python3.8-minimal",
      "value": 4
    },
    {
      "name": "libyaml-0-2",
      "value": 3
    }
  ];
  
  export const paramlist = ["added", "removed", "modified"]


export const networkInterfacesTableData  = [
    {
        "id": "br-27a7b606a41f",
        "mac": "02:42:28:6b:4d:29",
        "state": "up",
        "mtu": "1500",
        "type": "ethernet"

    },
    {
        "id": "br-4f4749229c28",
        "mac": "02:42:7e:9e:f5:22",
        "state": "down",
        "mtu": "1500",
        "type": "ethernet"

    },
    {
        "id": "br-5c128699e939",
        "mac": "02:42:03:31:90:79",
        "state": "up",
        "mtu": "1500",
        "type": "ethernet"

    },
   
  ];


export const mockLineData = [
  {
      id: "Critical",
      color: tokens("dark").redAccent[600],
      data: [
          {
              x: "2024-06-17",
              y: 0,
          },
          {
              x: "2024-06-18",
              y: 0,
          },
          {
              x: "2024-06-19",
              y: 0,
          },
          {
              x: "2024-06-20",
              y: 0,
          },
          {
              x: "2024-06-21",
              y: 0,
          },
          {
              x: "2024-06-22",
              y: 0,
          },
          {
            x: "2024-06-23",
            y: 0,
         },

         
      ],
  }
  ,
  {
    id: "High",
    color: tokens("dark").redAccent[500],
    data: [
        {
            x: "2024-06-17",
            y: 0,
        },
        {
            x: "2024-06-18",
            y: 0,
        },
        {
            x: "2024-06-19",
            y: 0,
        },
        {
            x: "2024-06-20",
            y: 0,
        },
        {
            x: "2024-06-21",
            y: 0,
        },
        {
            x: "2024-06-22",
            y: 0,
        },
        {
          x: "2024-06-23",
          y: 0,
       },

       
    ],
}
,
{
    id: "Medium",
    color: tokens("dark").redAccent[400],
    data: [
        {
            x: "2024-06-17",
            y: 3,
        },
        {
            x: "2024-06-18",
            y: 0,
        },
        {
            x: "2024-06-19",
            y: 8,
        },
        {
            x: "2024-06-20",
            y: 8,
        },
        {
            x: "2024-06-21",
            y: 0,
        },
        {
            x: "2024-06-22",
            y: 0,
        },
        {
          x: "2024-06-23",
          y: 0,
       },

       
    ],
}
,
{
    id: "Low",
    color: tokens("dark").redAccent[300],
    data: [
        {
            x: "2024-06-17",
            y: 1,
        },
        {
            x: "2024-06-18",
            y: 0,
        },
        {
            x: "2024-06-19",
            y: 0,
        },
        {
            x: "2024-06-20",
            y: 0,
        },
        {
            x: "2024-06-21",
            y: 0,
        },
        {
            x: "2024-06-22",
            y: 0,
        },
        {
          x: "2024-06-23",
          y: 0,
       },

       
    ],
}
];


export const mockBarData = [
    {
      country: "2024-06-17",
      added: 137,
      addedColor: "hsl(229, 70%, 50%)",
      removed: 96,
      removedColor: "hsl(296, 70%, 50%)",
      modified: 72,
      modifiedColor: "hsl(97, 70%, 50%)",
    },
    {
      country: "2024-06-18",
      added: 55,
      addedColor: "hsl(307, 70%, 50%)",
      removed: 28,
      removedColor: "hsl(111, 70%, 50%)",
      modified: 58,
      modifiedColor: "hsl(273, 70%, 50%)",

    },
    {
      country: "2024-06-19",
      added: 109,
      addedColor: "hsl(72, 70%, 50%)",
      removed: 23,
      removedColor: "hsl(96, 70%, 50%)",
      modified: 34,
      modifiedColor: "hsl(106, 70%, 50%)",

    },
    {
      country: "2024-06-20",
      added: 133,
      addedColor: "hsl(257, 70%, 50%)",
      removed: 52,
      removedColor: "hsl(326, 70%, 50%)",
      modified: 43,
      modifiedColor: "hsl(110, 70%, 50%)",

    },
    {
        country: "2024-06-21",
        added: 433,
        addedColor: "hsl(257, 70%, 50%)",
        removed: 531,
        removedColor: "hsl(326, 70%, 50%)",
        modified: 54,
        modifiedColor: "hsl(110, 70%, 50%)",
  
      },
      {
        country: "2024-06-22",
        added: 10,
        addedColor: "hsl(257, 70%, 50%)",
        removed: 52,
        removedColor: "hsl(326, 70%, 50%)",
        modified: 700,
        modifiedColor: "hsl(110, 70%, 50%)",
  
      },
      {
        country: "2024-06-23",
        added: 150,
        addedColor: "hsl(257, 70%, 50%)",
        removed: 52,
        removedColor: "hsl(326, 70%, 50%)",
        modified: 201,
        modifiedColor: "hsl(110, 70%, 50%)",
  
      },
      
  ];


export const detailsTableData = [
    {
        "id": "1",
        "policy": "Ubuntu OS lInux BenchMark v1.0.0",
        "endScan": "5-2-2024 003541",
        "passed": "77",
        "failed": "103",
        "score": "42%"
    },
   
  ];
  export const NetworkPortsTableData = [
    {
        "id": "22",
        "localIpAddress": "::",
        "process": "sshd",
        "processId": "19903",
        "state": "listening",
        "protocol": "tcp6"
    },
    {
        "id": "22",
        "localIpAddress": "0.0.0.0",
        "process": "sshd",
        "processId": "19903",
        "state": "listening",
        "protocol": "tcp"
    },
    {
        "id": "53",
        "localIpAddress": "127.0.0.53",
        "process": "systemd-resolve",
        "processId": "18779",
        "state": "listening",
        "protocol": "tcp"
    },
    {
        "id": "53",
        "localIpAddress": "127.0.0.53",
        "process": "sshd",
        "processId": "18779",
        "state": "listening",
        "protocol": "udp"
    },
    {
        "id": "514",
        "localIpAddress": "0.0.0.0",
        "process": "docker-proxy",
        "processId": "2863501",
        "state": "",
        "protocol": "udp"
    },
   
  ];


  export const packagesTableData = [
    {
        "id": "Automat",
        "architecture": "",
        "version": "20.2.0",
        "vendor": ""
    },
    {
        "id": "Babel",
        "architecture": "",
        "version": "2.8.0",
        "vendor": ""
    },
    {
        "id": "Jinja2",
        "architecture": "",
        "version": "3.0.3",
        "vendor": ""
    },
    {
        "id": "Markdown",
        "architecture": "",
        "version": "3.3.6",
        "vendor": ""
    },
    {
        "id": "MarkupSafe",
        "architecture": "",
        "version": "2.0.1",
        "vendor": ""
    },
    {
        "id": "PyGObject",
        "architecture": "",
        "version": "3.42.1",
        "vendor": ""
    },
    {
        "id": "PyHamcrest",
        "architecture": "",
        "version": "2.0.2",
        "vendor": ""
    },
    {
        "id": "PyJWT",
        "architecture": "",
        "version": "2.3.0",
        "vendor": ""
    },
    {
        "id": "PyYAML",
        "architecture": "",
        "version": "5.4.1",
        "vendor": ""
    },
    {
        "id": "Pygments",
        "architecture": "",
        "version": "2.11.2",
        "vendor": ""
    },

   
  ];

  export const networkSettingsTableData = [
    {
        "id": "br-27a7b606a41f",
        "address": "192.168.192.1",
        "netmask": "255.255.240.0",
        "protocol": "ipv4",
        "broadcast": "192.168.207.255"
    },
    {
        "id": "br-4f4749229c28",
        "address": "192.168.128.1",
        "netmask": "255.255.240.0",
        "protocol": "ipv4",
        "broadcast": "192.168.143.255"
    },
    {
        "id": "br-5c128699e939",
        "address": "172.28.0.1",
        "netmask": "255.255.0.0",
        "protocol": "ipv4",
        "broadcast": "172.28.255.255"
    },
    {
        "id": "br-892c862d42f4",
        "address": "172.30.0.1",
        "netmask": "255.255.0.0",
        "protocol": "ipv4",
        "broadcast": "172.30.255.255"
    },
    {
        "id": "br-951003b5580b",
        "address": "192.168.144.1",
        "netmask": "255.255.240.0",
        "protocol": "ipv4",
        "broadcast": "192.168.159.255"
    },
   
  ];


export const networkProcessesTableData = [
    {
        "id": "1403892",
        "name": "(sd-pam)",
        "effectiveUser": "root",
        "effectiveGroup": "root",
        "pid": "1403892",
        "command": "(sd-pam)",
        "vmSize": "171544",
        "size": "42886",
        "session": "1403891"
    },
    {
        "id": "1042273",
        "name": "ModemManager",
        "effectiveUser": "root",
        "effectiveGroup": "root",
        "pid": "1042273",
        "command": "/usr/sbin/ModemManager",
        "vmSize": "318032",
        "size": "79508",
        "session": "1042273"
    },
    {
        "id": "147",
        "name": "acpi_thermal_pm",
        "effectiveUser": "root",
        "effectiveGroup": "root",
        "pid": "147",
        "command": "",
        "vmSize": "0",
        "size": "0",
        "session": "0"
    },
    {
        "id": "767441",
        "name": "agent",
        "effectiveUser": "root",
        "effectiveGroup": "root",
        "pid": "767441",
        "command": "./agent",
        "vmSize": "761012",
        "size": "190253",
        "session": "767441"
    },
    {
        "id": "763",
        "name": "agetty",
        "effectiveUser": "root",
        "effectiveGroup": "root",
        "pid": "763",
        "command": "/sbin/agetty",
        "vmSize": "6172",
        "size": "1543",
        "session": "763"
    },
    {
        "id": "121",
        "name": "ata_sff",
        "effectiveUser": "root",
        "effectiveGroup": "root",
        "pid": "121",
        "command": "",
        "vmSize": "0",
        "size": "0",
        "session": "0"
    },

   
  ];


const AgentInventory = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams(); 
  const agentID = "Agent-" + id + " Inventory"


const networkInterfacesColumns = [
    { field: "id", headerName: "Name" },
    {
        field: "mac",
        headerName: "MAC",
        flex: 1,
        cellClassName: "name-column--cell",
      },
    {
      field: "state",
      headerName: "State",
      flex: 1,
    },
    {
      field: "mtu",
      headerName: "MTU",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
    },
  ];
  

const NetworkPortsColumns = [
    { field: "id", headerName: "Local Port" },
    {
      field: "localIpAddress",
      headerName: "Local IP Address",
      flex: 1,
    },
    {
        field: "process",
        headerName: "Process",
        flex: 1,
      },
    {
      field: "processId",
      headerName: "Process ID",
      flex: 1,
    },

    {
      field: "state",
      headerName: "State",
      flex: 1,
    },
    {
        field: "protocol",
        headerName: "Protocol",
        flex: 1,
      },
  ];






const packagesColumns = [
    { field: "id", headerName: "Name" },
    {
      field: "architecture",
      headerName: "Architecture",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "version",
      headerName: "Version",
      flex: 1,
    },
    {
      field: "vendor",
      headerName: "Vendor",
      flex: 1,
    },

  ];


  const networkSettingsColumns = [
    { field: "id", headerName: "Interface" },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "netmask",
      headerName: "NetMask",
      flex: 1,
    },
    {
      field: "protocol",
      headerName: "Protocol",
      flex: 1,
    },

    {
      field: "broadcast",
      headerName: "BroadCast",
      flex: 1,
    },
  ];


const processColumns = [
    { field: "id", headerName: "Process ID" },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "effective",
      headerName: "Effective",
      flex: 1,
    },
    {
      field: "effectiveGroup",
      headerName: "Effective Group",
      flex: 1,
    },


    {
      field: "command",
      headerName: "Command",
      flex: 1,
    },

    {
      field: "vmSize",
      headerName: "VM Size",
      flex: 1,
    },
    {
      field: "size",
      headerName: "Size",
      flex: 1,
    },
    {
      field: "session",
      headerName: "Session",
      flex: 1,
    },
  ];




  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={agentID} subtitle="" />

        <Box   >
        <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            
            Stats
          </Button>
          |
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            
            Inventory
          </Button>
           | 
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            
            Quarantine
          </Button>
          | 
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            
            Active Response
          </Button>
          | 
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            
            Command
          </Button>
          


        </Box>

      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
 




        {/* ROW 3 */}

        <Box
          gridColumn="span 5"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        
          
        height="37vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            
            
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },

        }}

        >
            


            <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
            Network Interfaces
            </Typography>
          </Box>

        <DataGrid checkboxSelection rows={networkInterfacesTableData} columns={networkInterfacesColumns} rowHeight={25} headerHeight={25}  />
        </Box>


        {/* ROW 3 */}
        <Box
          gridColumn="span 7"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        
          
        height="37vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            
            
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },

        }}

        >

           <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Network Ports
            </Typography>
          </Box>

        <DataGrid checkboxSelection rows={NetworkPortsTableData} columns={NetworkPortsColumns} rowHeight={25} headerHeight={25}  />
        </Box>


        {/* ROW 3 */}


        <Box
          gridColumn="span 5"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        
          
        height="37vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            
            
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },

        }}

        >
            


            <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
            Packages
            </Typography>
          </Box>

        <DataGrid checkboxSelection rows={packagesTableData} columns={packagesColumns} rowHeight={25} headerHeight={25}  />
        </Box>



        <Box
          gridColumn="span 7"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        
          
        height="37vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            
            
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },

        }}

        >
            


            <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
            Network Settings
            </Typography>
          </Box>

        <DataGrid checkboxSelection rows={networkSettingsTableData} columns={networkSettingsColumns} rowHeight={25} headerHeight={25}  />
        </Box>

        {/* ROW 4 */}

        

        {/* end of ROW 4 */}


         {/* ROW 5 */}
         <Box
         gridColumn="span 12"
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}

      >
                    <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
            backgroundColor={colors.primary[400]}
          >
        <Typography color={colors.grey[100]} variant="h5" fontWeight="600"  >
            Processes
        </Typography>
          </Box>

        <DataGrid checkboxSelection rows={networkProcessesTableData} columns={processColumns} headerHeight={25}  />
      </Box>
         {/* ROW 5 END */}

      </Box>


    </Box>
  );
};

export default AgentInventory;
