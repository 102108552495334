import Form from "./form";
import { tokens } from "../theme";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";

const SingIn = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Form />
    );


};

export default SingIn;
