import * as React from 'react';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import SecurityIcon from '@mui/icons-material/Security';
import BugReportIcon from '@mui/icons-material/BugReport';
import ElectricalServicesSharpIcon from '@mui/icons-material/ElectricalServicesSharp';
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import AppsOutageIcon from '@mui/icons-material/AppsOutage';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import { DataGrid } from "@mui/x-data-grid";
import { mockDataInvoices } from "../../data/mockData";

function createData(date, description, status, severity, customer, owner, alertNumber) {
  return {
    date,
    description,
    status, 
    severity, 
    customer, 
    owner,
    alertNumber,
    details: [
      {
        date: '05/19/2024 02:47:56',
        state: 'Open',
        irisLInke: 'https://iris-link',
      }
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{row.date}</TableCell>
        <TableCell component="th" scope="row">
          {row.description}
        </TableCell>
        <TableCell align="right">{row.status}</TableCell>
        <TableCell align="right">{row.severity}</TableCell>
        <TableCell align="right">{row.customer}</TableCell>
        <TableCell align="right">{row.owner}</TableCell>
        <TableCell align="right">{row.alertNumber}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>State</TableCell>
                    <TableCell>Link to IRIS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.details.map((detailsRow) => (
                    <TableRow key={detailsRow.date}>
                      <TableCell component="th" scope="row">
                        {detailsRow.date}
                      </TableCell>                        
                      <TableCell component="th" scope="row">
                        {detailsRow.state}
                      </TableCell>
                      <TableCell>{detailsRow.irisLInke}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    date: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    detials: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        irisLInke: PropTypes.string.isRequired,
      }),
    ).isRequired,
    severity: PropTypes.string.isRequired,
    customer: PropTypes.string.isRequired,
    owner: PropTypes.string.isRequired,
    alertNumber: PropTypes.string.isRequired,
  }).isRequired,
};

const rows = [
  createData('05/19/2024 02:47:56', 'User missed the password more than one time', 'Open', 'High', 'austra', 'copliot', '1'),
  createData('05/19/2024 02:47:56', ' User missed the password more than one time', 'Open', 'High', 'austra', 'copliot', '1'),
  createData('05/19/2024 02:47:56', ' User missed the password more than one time', 'Open', 'High', 'austra', 'copliot', '1'),
  createData('05/19/2024 02:47:56', ' User missed the password more than one time', 'Open', 'High', 'austra', 'copliot', '1'),
  createData('05/19/2024 02:47:56', ' User missed the password more than one time', 'Open', 'High', 'austra', 'copliot', '1'),
];

const SocCases = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <Box m="20px">
    {/* HEADER */}
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Header title="SOC Alerts" subtitle="Browse through alerts" />

      <Box>
        <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
        >
          <DownloadOutlinedIcon sx={{ mr: "10px" }} />
          Download Reports
        </Button>
      </Box>

    </Box>
    <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[400]}`}
            colors={colors.greenAccent[400]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
            SOC Alerts
            </Typography>
          </Box>
    <TableContainer component={Paper} sx={{ backgroundColor: colors.primary[600] }} >
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow  >
            <TableCell />
            <TableCell sx={{ fontWeight: "bold", fontSize: "14px" }} >Date</TableCell>
            <TableCell sx={{ fontWeight: "bold", fontSize: "14px" }} >Description</TableCell>
            <TableCell sx={{ fontWeight: "bold", fontSize: "14px" }} align="right">Status</TableCell>
            <TableCell sx={{ fontWeight: "bold", fontSize: "14px" }} align="right">Severity</TableCell>
            <TableCell  sx={{ fontWeight: "bold", fontSize: "14px" }} align="right">Customer</TableCell>
            <TableCell  sx={{ fontWeight: "bold", fontSize: "14px" }} align="right">Owner</TableCell>
            <TableCell  sx={{ fontWeight: "bold", fontSize: "14px" }} align="right">Alert#</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>

    </TableContainer>
    <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
export default SocCases;