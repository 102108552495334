import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import SecurityEventsDashboard from "./scenes/securityEvents"
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import FileIntegrityMonitoring from "./scenes/fim";
import VulnerabilitiesMonitoring from "./scenes/vulnerabilties"
import VirusTotal from "./scenes/virustotal";
import NistDashboard from "./scenes/nist";
import GdprDashboard from "./scenes/gdpr";
import PolicyMonitoringDashboard from "./scenes/policyMonitoring";
import PciDssDashboard from "./scenes/pcidss";
import AgentsDashboard from "./scenes/agents";
import AgentLevelView from "./scenes/agents/agentLevelView";
import AgentStats from "./scenes/agents/agentsStats";
import AgentInventory from "./scenes/agents/agentInventory";
import SingIn from "./scenes/signin";
import { AuthProvider, useAuth } from './AuthContext';
import LoginPage from './LoginPage';
import { BrowserRouter as Router, Navigate } from 'react-router-dom';
import SocAlerts from "./scenes/soc";
import SocCases from "./scenes/soc/cases";
import CustomerView from "./scenes/customers";
import UserView from "./scenes/users/users";
import IntegrationsView from "./scenes/integrations";
import ServicesView from "./scenes/services";
import CorrelationAlertsView from "./scenes/correlations/alerts";
import CorrelationEventsView from "./scenes/correlations/events";
import CorrelationMetricView from "./scenes/correlations/metrics";
import CorrelationPipelinesView from "./scenes/correlations/pipelines";
import CorrelationRulessView from "./scenes/correlations/rules";
import CorrelationStreamssView from "./scenes/correlations/streams";



const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to="/login" />;
};

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
        <AuthProvider>
        <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={ <PrivateRoute> <Dashboard /> </PrivateRoute> } />
        <Route path="/dashboard" element={
          <PrivateRoute>
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
              <Dashboard />
          </main>
          </PrivateRoute>
          } />
<Route path="/securityEvents" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <SecurityEventsDashboard />
    </main>
  </PrivateRoute>
} />

<Route path="/fim" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <FileIntegrityMonitoring />
    </main>
  </PrivateRoute>
} />

<Route path="/Vulnerabilities" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <VulnerabilitiesMonitoring />
    </main>
  </PrivateRoute>
} />

<Route path="/VirusTotal" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <VirusTotal />
    </main>
  </PrivateRoute>
} />

<Route path="/nist" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <NistDashboard />
    </main>
  </PrivateRoute>
} />

<Route path="/gdpr" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <GdprDashboard />
    </main>
  </PrivateRoute>
} />

<Route path="/policyMonitoring" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <PolicyMonitoringDashboard />
    </main>
  </PrivateRoute>
} />

<Route path="/pciDss" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <PciDssDashboard />
    </main>
  </PrivateRoute>
} />

<Route path="/agents" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <AgentsDashboard />
    </main>
  </PrivateRoute>
} />

<Route path="/bar" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <Bar />
    </main>
  </PrivateRoute>
} />

<Route path="/pie" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <Pie />
    </main>
  </PrivateRoute>
} />

<Route path="/line" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <Line />
    </main>
  </PrivateRoute>
} />

<Route path="/faq" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <FAQ />
    </main>
  </PrivateRoute>
} />

<Route path="/calendar" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <Calendar />
    </main>
  </PrivateRoute>
} />

<Route path="/geography" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <Geography />
    </main>
  </PrivateRoute>
} />

<Route path="/agentsDetails/:id" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <AgentLevelView />
    </main>
  </PrivateRoute>
} />

<Route path="/agentStats/:id" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <AgentStats />
    </main>
  </PrivateRoute>
} />

<Route path="/agentInventory/:id" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <AgentInventory />
    </main>
  </PrivateRoute>
} />

<Route path="/testform" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <AgentInventory />
    </main>
  </PrivateRoute>
} />

<Route path="/signIn" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <SingIn />
    </main>
  </PrivateRoute>
} />

<Route path="/socAlerts" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <SocAlerts />
    </main>
  </PrivateRoute>
} />

<Route path="/socCases" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <SocCases />
    </main>
  </PrivateRoute>
} />
<Route path="/customers" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <CustomerView />
    </main>
  </PrivateRoute>
} />



<Route path="/users" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <UserView />
    </main>
  </PrivateRoute>
} />



<Route path="/integrations" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <IntegrationsView />
    </main>
  </PrivateRoute>
} />

<Route path="/services" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <ServicesView />
    </main>
  </PrivateRoute>
} />


<Route path="/CorrelationAlertsView" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <CorrelationAlertsView />
    </main>
  </PrivateRoute>
} />

<Route path="/CorrelationEventsView" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <CorrelationEventsView />
    </main>
  </PrivateRoute>
} />

<Route path="/CorrelationMetricView" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <CorrelationMetricView />
    </main>
  </PrivateRoute>
} />


<Route path="/CorrelationPipelinesView" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <CorrelationPipelinesView />
    </main>
  </PrivateRoute>
} />

<Route path="/CorrelationRulessView" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <CorrelationRulessView />
    </main>
  </PrivateRoute>
} />


<Route path="/CorrelationStreamssView" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <CorrelationStreamssView />
    </main>
  </PrivateRoute>
} />

<Route path="/FactsCollectionView" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <CorrelationStreamssView />
    </main>
  </PrivateRoute>
} />

<Route path="/ThreatIntelligenceView" element={
  <PrivateRoute>
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />
      <CorrelationStreamssView />
    </main>
  </PrivateRoute>
} />





        
          </Routes>
          </AuthProvider>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
