import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import SecurityIcon from '@mui/icons-material/Security';
import BugReportIcon from '@mui/icons-material/BugReport';
import ElectricalServicesSharpIcon from '@mui/icons-material/ElectricalServicesSharp';
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import AppsOutageIcon from '@mui/icons-material/AppsOutage';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import { DataGrid } from "@mui/x-data-grid";
import { mockDataInvoices } from "../../data/mockData";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import WarningIcon from '@mui/icons-material/Warning';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import ReportIcon from '@mui/icons-material/Report';
import PieChart from "../../components/PieChart";
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';

import PestControlIcon from '@mui/icons-material/PestControl';
import { yellow } from "@mui/material/colors";


export const mockMostCommonCVEs = [
    {
      id: "CVE-2024-0397",
      label: "CVE-2024-0397",
      value: 8,
      color: "hsl(104, 70%, 50%)",
    },
    {
      id: "CVE-2024-4032",
      label: "CVE-2024-4032",
      value: 8,
      color: "hsl(162, 70%, 50%)",
    },
    {
      id: "CVE-2024-35325",
      label: "CVE-2024-35325",
      value: 1,
      color: "hsl(291, 70%, 50%)",
    },
    {
      id: "CVE-2024-35326",
      label: "CVE-2024-35326",
      value: 1,
      color: "hsl(229, 70%, 50%)",
    },
    {
      id: "CVE-2024-35328",
      label: "CVE-2024-35328",
      value: 1,
      color: "hsl(344, 70%, 50%)",
    },
  ];


export const mockPieData = [
    {
      id: "libpython3.8-minimal",
      label: "libpython3.8-minimal",
      value: 4,
      color: "hsl(104, 70%, 50%)",
    },
    {
      id: "libpython3.8-stdlib",
      label: "libpython3.8-stdlib",
      value: 4,
      color: "hsl(162, 70%, 50%)",
    },
    {
      id: "python3.8",
      label: "python3.8",
      value: 4,
      color: "hsl(291, 70%, 50%)",
    },
    {
      id: "python3.8-minimal",
      label: "python3.8-minimal",
      value: 4,
      color: "hsl(229, 70%, 50%)",
    },
    {
      id: "libyaml-0-2",
      label: "libyaml-0-2",
      value: 3,
      color: "hsl(344, 70%, 50%)",
    },
  ];
  


  export const mockSeverityDistribution = [
    {
      id: "Medium",
      label: "Medium",
      value: 19,
      color: "hsl(291, 70%, 50%)",
    },
    {
      id: "Low",
      label: "Low",
      value: 1,
      color: "hsl(344, 70%, 50%)",
    },
  ];






export const commonAffectedPackages = [
    {
      "name": "libpython3.8-minimal",
      "value": 4
    },
    {
      "name": "libpython3.8-stdlib",
      "value": 4
    },
    {
      "name": "python3.8",
      "value": 4
    },
    {
      "name": "python3.8-minimal",
      "value": 4
    },
    {
      "name": "libyaml-0-2",
      "value": 3
    }
  ];
  
  export const paramlist = ["added", "removed", "modified"]


export const detailsTableData = [
    {
        "id": "1a2b3c",
        "Name": "git-man",
        "Version": "1:2.20.1-2+deb10u8",
        "Architecture": "all",
        "Severity": "High",
        "CVE": "CVE-2021-29468",
        "CVSS2_Score": 6.8,
        "CVSS3_Score": 8.8,
        "Detection_Time": "May 21, 2024 @ 01:32:05.000"
    },
    {
        "id": "4d5e6f",
        "Name": "git-man",
        "Version": "1:2.20.1-2+deb10u8",
        "Architecture": "all",
        "Severity": "High",
        "CVE": "CVE-2023-29007",
        "CVSS2_Score": 0,
        "CVSS3_Score": 7.8,
        "Detection_Time": "May 21, 2024 @ 01:32:05.000"
    },
    {
        "id": "7g8h9i",
        "Name": "git-man",
        "Version": "1:2.20.1-2+deb10u8",
        "Architecture": "all",
        "Severity": "High",
        "CVE": "CVE-2023-25652",
        "CVSS2_Score": 0,
        "CVSS3_Score": 7.5,
        "Detection_Time": "May 21, 2024 @ 01:32:06.000"
    },
    {
        "id": "j1k2l3",
        "Name": "git-man",
        "Version": "1:2.20.1-2+deb10u8",
        "Architecture": "all",
        "Severity": "Critical",
        "CVE": "CVE-2024-32002",
        "CVSS2_Score": 0,
        "CVSS3_Score": 9,
        "Detection_Time": "May 29, 2024 @ 00:47:28.000"
    },
    {
        "id": "m4n5o6",
        "Name": "grub-common",
        "Version": "2.06-3~deb10u4",
        "Architecture": "amd64",
        "Severity": "Low",
        "CVE": "CVE-2021-3981",
        "CVSS2_Score": 2.1,
        "CVSS3_Score": 3.3,
        "Detection_Time": "May 21, 2024 @ 01:32:04.000"
    },
    {
        "id": "p7q8r9",
        "Name": "grub-efi-amd64-bin",
        "Version": "2.06-3~deb10u4",
        "Architecture": "amd64",
        "Severity": "Low",
        "CVE": "CVE-2021-3981",
        "CVSS2_Score": 2.1,
        "CVSS3_Score": 3.3,
        "Detection_Time": "May 21, 2024 @ 01:32:04.000"
    },
    {
        "id": "s1t2u3",
        "Name": "grub-pc-bin",
        "Version": "2.06-3~deb10u4",
        "Architecture": "amd64",
        "Severity": "Low",
        "CVE": "CVE-2021-3981",
        "CVSS2_Score": 2.1,
        "CVSS3_Score": 3.3,
        "Detection_Time": "May 21, 2024 @ 01:32:04.000"
    },
    {
        "id": "v4w5x6",
        "Name": "grub2-common",
        "Version": "2.06-3~deb10u4",
        "Architecture": "amd64",
        "Severity": "Low",
        "CVE": "CVE-2021-3981",
        "CVSS2_Score": 2.1,
        "CVSS3_Score": 3.3,
        "Detection_Time": "May 21, 2024 @ 01:32:04.000"
    },
    {
        "id": "y7z8a9",
        "Name": "libc-bin",
        "Version": "2.28-10+deb10u3",
        "Architecture": "amd64",
        "Severity": "Medium",
        "CVE": "CVE-2023-4813",
        "CVSS2_Score": 0,
        "CVSS3_Score": 5.9,
        "Detection_Time": "May 21, 2024 @ 01:32:06.000"
    },
    {
        "id": "b1c2d3",
        "Name": "libc6",
        "Version": "2.28-10+deb10u3",
        "Architecture": "amd64",
        "Severity": "Medium",
        "CVE": "CVE-2023-4813",
        "CVSS2_Score": 0,
        "CVSS3_Score": 5.9,
        "Detection_Time": "May 21, 2024 @ 01:32:06.000"
    },
  ];


export const mockLineData = [
  {
      id: "Critical",
      color: tokens("dark").redAccent[600],
      data: [
          {
              x: "2024-06-17",
              y: 0,
          },
          {
              x: "2024-06-18",
              y: 0,
          },
          {
              x: "2024-06-19",
              y: 0,
          },
          {
              x: "2024-06-20",
              y: 0,
          },
          {
              x: "2024-06-21",
              y: 0,
          },
          {
              x: "2024-06-22",
              y: 0,
          },
          {
            x: "2024-06-23",
            y: 0,
         },

         
      ],
  }
  ,
  {
    id: "High",
    color: tokens("dark").redAccent[500],
    data: [
        {
            x: "2024-06-17",
            y: 0,
        },
        {
            x: "2024-06-18",
            y: 0,
        },
        {
            x: "2024-06-19",
            y: 0,
        },
        {
            x: "2024-06-20",
            y: 0,
        },
        {
            x: "2024-06-21",
            y: 0,
        },
        {
            x: "2024-06-22",
            y: 0,
        },
        {
          x: "2024-06-23",
          y: 0,
       },

       
    ],
}
,
{
    id: "Medium",
    color: tokens("dark").redAccent[400],
    data: [
        {
            x: "2024-06-17",
            y: 3,
        },
        {
            x: "2024-06-18",
            y: 0,
        },
        {
            x: "2024-06-19",
            y: 8,
        },
        {
            x: "2024-06-20",
            y: 8,
        },
        {
            x: "2024-06-21",
            y: 0,
        },
        {
            x: "2024-06-22",
            y: 0,
        },
        {
          x: "2024-06-23",
          y: 0,
       },

       
    ],
}
,
{
    id: "Low",
    color: tokens("dark").redAccent[300],
    data: [
        {
            x: "2024-06-17",
            y: 1,
        },
        {
            x: "2024-06-18",
            y: 0,
        },
        {
            x: "2024-06-19",
            y: 0,
        },
        {
            x: "2024-06-20",
            y: 0,
        },
        {
            x: "2024-06-21",
            y: 0,
        },
        {
            x: "2024-06-22",
            y: 0,
        },
        {
          x: "2024-06-23",
          y: 0,
       },

       
    ],
}
];


export const mockBarData = [
    {
      country: "2024-06-17",
      added: 137,
      addedColor: "hsl(229, 70%, 50%)",
      removed: 96,
      removedColor: "hsl(296, 70%, 50%)",
      modified: 72,
      modifiedColor: "hsl(97, 70%, 50%)",
    },
    {
      country: "2024-06-18",
      added: 55,
      addedColor: "hsl(307, 70%, 50%)",
      removed: 28,
      removedColor: "hsl(111, 70%, 50%)",
      modified: 58,
      modifiedColor: "hsl(273, 70%, 50%)",

    },
    {
      country: "2024-06-19",
      added: 109,
      addedColor: "hsl(72, 70%, 50%)",
      removed: 23,
      removedColor: "hsl(96, 70%, 50%)",
      modified: 34,
      modifiedColor: "hsl(106, 70%, 50%)",

    },
    {
      country: "2024-06-20",
      added: 133,
      addedColor: "hsl(257, 70%, 50%)",
      removed: 52,
      removedColor: "hsl(326, 70%, 50%)",
      modified: 43,
      modifiedColor: "hsl(110, 70%, 50%)",

    },
    {
        country: "2024-06-21",
        added: 433,
        addedColor: "hsl(257, 70%, 50%)",
        removed: 531,
        removedColor: "hsl(326, 70%, 50%)",
        modified: 54,
        modifiedColor: "hsl(110, 70%, 50%)",
  
      },
      {
        country: "2024-06-22",
        added: 10,
        addedColor: "hsl(257, 70%, 50%)",
        removed: 52,
        removedColor: "hsl(326, 70%, 50%)",
        modified: 700,
        modifiedColor: "hsl(110, 70%, 50%)",
  
      },
      {
        country: "2024-06-23",
        added: 150,
        addedColor: "hsl(257, 70%, 50%)",
        removed: 52,
        removedColor: "hsl(326, 70%, 50%)",
        modified: 201,
        modifiedColor: "hsl(110, 70%, 50%)",
  
      },
      
  ];



const VirusTotal = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);



  const columns = [
    { field: "id", headerName: "ID" },
    {
        field: "Name",
        headerName: "Name",
        flex: 1,
        cellClassName: "name-column--cell",
      },

    {
      field: "Version",
      headerName: "Version",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "Architecture",
      headerName: "Architecture",
      flex: 1,
    },
    {
      field: "Severity",
      headerName: "Severity",
      flex: 1,
    },

    {
        field: "CVE",
        headerName: "CVE",
        flex: 1,
      },

      {
        field: "CVSS2_Score",
        headerName: "CVSS2_Score",
        flex: 1,
      },

      {
        field: "CVSS3_Score",
        headerName: "CVSS3_Score",
        flex: 1,
      },

      {
        field: "Detection_Time",
        headerName: "Detection_Time",
        flex: 1,
      },
  ];





  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Malware Detections" subtitle="Analyse suspicious files." />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>

      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="0"
            subtitle="Total Malicious"
            progress="0"
            increase="+0%"
            icon={
              <BugReportIcon  
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="0"
            subtitle="Total Positives"
            progress="0"
            increase="+0%"
            icon={
              <PestControlIcon 
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="9"
            subtitle="Total Scanned"
            progress="1"
            increase="+100%"
            icon={
              <ContentPasteSearchIcon   
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>






        {/* ROW 2 */}
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Severity Distribution
          </Typography>

            
                       
            <Box height="33vh">
                <PieChart pData={mockSeverityDistribution} />
            </Box>


        </Box>


        {/* ROW 2 */}
        <Box

        gridColumn="span 6"
        gridRow="span 2"
        backgroundColor={colors.primary[400]}
      >
        <Typography
          variant="h5"
          fontWeight="600"
          sx={{ padding: "30px 30px 0 30px" }}
        >
          Severity Distribution
        </Typography>

          
                     
          <Box height="33vh">
              <PieChart pData={mockSeverityDistribution} />
          </Box>


      </Box>


        {/* ROW 3 */}
         <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Top 5 Evolution
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} srvData={mockBarData} paramKeys={paramlist} />
          </Box>
        </Box>






         {/* ROW 5 */}
         <Box
         gridColumn="span 12"
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid checkboxSelection rows={detailsTableData} columns={columns} />
      </Box>
         {/* ROW 5 END */}

      </Box>


    </Box>
  );
};

export default VirusTotal;
